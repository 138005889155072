/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';
import 'slick-carousel';

function initHeroSlider() {
	const slideContent = $('.home-hero-content.single-slide__content__container');
	function changeSlideContent(slideEvent) {
		$('.hero-slider').on(slideEvent, (event, slick, currentSlide) => {
			if (undefined === currentSlide) {
				currentSlide = 0;
			}
			const slideData = $('.hero-slider').find('.single-slide').eq(currentSlide).attr('data-slide-data');
			try {
				const parsedSlideData = JSON.parse(slideData);
				$('.single-slide__content').show();
				let slideContentHtml = '';
				if (undefined !== parsedSlideData.slide_heading) {
					slideContentHtml += `<h2>${parsedSlideData.slide_heading}</h2>`;
				}

				if (undefined !== parsedSlideData.slide_content) {
					slideContentHtml += `<p>${parsedSlideData.slide_content}</p>`;
				}

				if (undefined !== parsedSlideData.slide_link) {
					slideContentHtml += `<a href="${parsedSlideData.slide_link.url}"
											class="btn btn--primary single-slide__btn" target="${parsedSlideData.slide_link.target}"
										>
										${parsedSlideData.slide_link.title}
										</a>`;
				}
				slideContent.html(slideContentHtml);
			} catch (e) {
				slideContent.html('');
				$('.single-slide__content').hide();
			}
		});
	}
	changeSlideContent('init');
	changeSlideContent('afterChange');

	$('.hero-slider').slick({
		slides: '.single-slide',
		lazyLoad: 'ondemand',
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		appendDots: '.home-hero-content.em-dots',
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		fade: true,
		cssEase: 'linear',
	});
}

document.addEventListener('DOMContentLoaded', initHeroSlider);
window.addEventListener('resize', () => {
	if (!$('.hero-slider').hasClass('slick-initialized')) {
		initHeroSlider();
	}
});
