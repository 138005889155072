/**
 * Scroll-to functionality for hash links on the document
 *
 * @package Embark
 * @since   1.0.0
 */

/* eslint-disable */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/**
 * A collection of helper functions for use throughout the project.
 *
 * @since 1.0.0
 */
import * as helpers from '../global/helpers';

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import { els } from './variables';

/**
 * Scroll to hash on load.
 *
 * @since  1.0.0
 */
function scrollToHashOnLoad() {
	if (window.location.hash) {
		// To top right away.
		scroll(0, 0);
		// Void some browsers issue.
		setTimeout(() => {
			scroll(0, 0);
		}, 1);

		let thisOffsetHeight = 0;

		if (els.header) {
			thisOffsetHeight = els.header.offsetHeight;
		}


		helpers.scrollToEl($(window.location.hash), thisOffsetHeight);
	}
}

/**
 * Scroll to hash on click, and update URL.
 *
 * @since  1.0.0
 */
function scrollToHashOnClick() {
	if (!els.naviconTrigger) {
		return;
	}
	els.html.classList.remove('html--menu-open');
	els.naviconTrigger.classList.remove('navicon__trigger--open');
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
		const hash = this.hash;
		let target = $(hash);
		target = target.length ? target : $(`[name=${hash.slice(1)}]`);

		let thisOffsetHeight = 0;

		if (els.header) {
			thisOffsetHeight = els.header.offsetHeight;
		}

		if (target.length) {
			helpers.scrollToEl(target, thisOffsetHeight);
			helpers.addHashToURL(hash);
			return false;
		}
	}
}

function scrollToStories() {
	const stories = $('.stories');
	if(window.location.href.indexOf("page") > -1 && stories.length > 0 ) {
    	helpers.scrollToEl(stories, els.header.offsetHeight);
    }
}


window.addEventListener('load', function(){
	scrollToHashOnLoad();
	scrollToStories();
});
$(document).on('click', 'a[href*="#"]:not([href="#"])', scrollToHashOnClick);
