/**
 * CountUp.js
 *
 * @package Embark
 * @since   1.0.1
 * @link https://www.npmjs.com/package/countup.js
 */

/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

function emHeaderSearch() {
	const header = $('.site-header');
	const menuItem = document.querySelector('.site-header');
	const searchInput = $('.header-search input');

	$('.menu-item-search').click(() => {
		if (!header.hasClass('search-open')) {
			header.addClass('search-open');
			searchInput.focus();
		} else {
			header.removeClass('search-open');
		}
	});

	document.addEventListener('click', (e) => {
		if (!menuItem.contains(e.target)) {
			header.removeClass('search-open');
		}
	});
}

window.addEventListener('load', emHeaderSearch);
