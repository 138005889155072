if (document.querySelector('.em-post-share-modal')) {
	const postShareInit = () => {
		const shareModal = document.querySelector('.em-post-share-modal');
		const shareBtn = document.querySelector('.em-post-share-checkbox');
		const closeBtn = document.querySelector('.em-post-share-modal__close');
		const toggleShareModal = () => {
			const modal = document.querySelector('.em-post-share-modal');
			modal.classList.toggle('visible');
		};

		shareBtn.addEventListener('change', toggleShareModal);
		closeBtn.addEventListener('click', toggleShareModal);
		closeBtn.addEventListener('keydown', (e) => {
			if (e.key === 'Enter') {
				toggleShareModal();
			}
		});
		shareModal.addEventListener('click', (e) => {
			if (e.target.closest('.em-post-share-modal__container') || e.target.closest('.em-post-share-modal__close')) {
				return;
			}
			toggleShareModal();
		});
	};
	postShareInit();
}

