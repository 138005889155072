/* eslint-disable arrow-parens */

class HomeCardsItem {
	constructor(el) {
		this.el = el;
		this.cardContent = this.el.querySelector('.home-cards__item__content-body');
		this.cardBodyInner = this.el.querySelector('.home-cards__item__body__inner');
		this.hideCardContent();
		this.el.addEventListener('mouseover', () => {
			this.showCardContent();
		});

		this.el.addEventListener('mouseout', () => {
			this.hideCardContent();
		});

		window.addEventListener('resize', () => {
			this.hideCardContent();
		});
	}

	hideCardContent() {
		const cardBox = this.cardContent.getBoundingClientRect();
		this.cardBodyInner.style.top = `${cardBox.height}px`;
	}

	showCardContent() {
		this.cardBodyInner.style.top = '0px';
	}
}

const homeCards = [...document.querySelectorAll('.home-cards__item')];
if (homeCards.length) {
	homeCards.map((el) => new HomeCardsItem(el));
}
