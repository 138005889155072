import $ from 'jquery';
import 'slick-carousel';

function initRelatedPosts(el) {
	console.log(el);

	const relatedPrev = document.querySelector('.em-related-posts__slider__pagination__button.em-prev-btn');
	const relatedNext = document.querySelector('.em-related-posts__slider__pagination__button.em-next-btn');
	const relateddots = document.querySelector('.em-related-posts__slider__pagination');

	const slider = $(el).slick({
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: true,
		appendDots: relateddots,
		arrows: false,
		centerMode: false,
	});



	if (relatedPrev) {
		relatedPrev.addEventListener('click', () => {
			slider.slick('slickPrev');
		});
	}

	if (relatedNext) {
		relatedNext.addEventListener('click', () => {
			slider.slick('slickNext');
		});
	}


	slider.on('afterChange', (event, slick, currentSlide) => {
		const slideImg = document.querySelector('.em-related-posts__img img');
		const slide = document.querySelector('.em-related-posts__slider .slick-slide.slick-active');
		const currentSlideImg = slide.querySelector('.em-related-posts__slide__content').getAttribute('data-img-src');
		slideImg.src = currentSlideImg;
	});
};


document.addEventListener('DOMContentLoaded', () => {
	const relatedPostSlider = document.querySelector('.em-related-posts__slider__container');
	if (!relatedPostSlider === null) {
		initRelatedPosts(relatedPostSlider);
	}
});


