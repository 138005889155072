/**
 * Responsive Tables
 *
 * @package Embark
 * @since   1.0.0
 */

import $ from 'jquery';

function initResponsiveTables() {
	$('table').wrap('<div class="table-wrap"/>');
}

document.addEventListener('DOMContentLoaded', initResponsiveTables);
