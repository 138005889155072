/**
 * CountUp.js
 *
 * @package Embark
 * @since   1.0.1
 * @link https://www.npmjs.com/package/countup.js
 */

/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';


function sideNavSelect() {
	$('.side-nav select').change(function () {
		const val = $(this).val();

		if (val !== '0') {
			window.location.href = $(this).val();
		}
	});

	$('.side-nav select').each(function () {
		const select = $(this);
		const selectedValue = select.find('option[selected]').val();

		if (selectedValue) {
			select.val(selectedValue);
		} else {
			select.attr('selected', 'selected');
		}
	});
}

document.addEventListener('DOMContentLoaded', sideNavSelect);
