/**
 * Neuro Night Layers
 *
 * @package Embark
 * @since   1.0.1
 */

/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';
import 'slick-carousel';
import AOS from 'aos';
import { els, breakpoints } from './variables';

function initSliders() {
	const slideCount = $('.card-container .single-card');
	const slideToShow = $('.card-container').children('.single-card').length;

	if (slideToShow > 3) {
		$('.card-container').slick({
			slides: '.single-card',
			infinite: true,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 3,
			prevArrow: '<button type="button" class="slick-prev"><span class="fa-stack fa-lg"><i class="fa fa-angle-left fa-stack-1x" aria-hidden="true"></i></span></button>',
			nextArrow: '<button type="button" class="slick-next"><span class="fa-stack fa-lg"><i class="fa fa-angle-right fa-stack-1x" aria-hidden="true"></i></span></button>',
			dots: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						infinite: true,
					},
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});
	}


	$('.slide-contain').slick({
		slides: '.single-slide',
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: '<button type="button" class="slick-prev"><span class="fa-stack fa-lg"><i class="fa fa-angle-left fa-stack-1x" aria-hidden="true"></i></span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="fa-stack fa-lg"><i class="fa fa-angle-right fa-stack-1x" aria-hidden="true"></i></span></button>',
		dots: true,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false,
				},
			},
		],
	});
}

function initFloatingButton() {
	$('.floating-button').css({ display: 'block' });
}

function initGFormLightbox() {
	$('.gform-link').on('click', (e) => {
		const lightbox = $('.gform-lightbox');
		// console.log(e);
		lightbox.addClass('active');
		$('body').addClass('lightbox-active');
	});

	$('.gform-lightbox__close').on('click', () => {
		const lightbox = $('.gform-lightbox');
		$('body').removeClass('lightbox-active');
		lightbox.removeClass('active');
	});
}

function initNavScroll() {
	let lastID;
	const header = $('.tmpl-neuronight-header');
	const headerHeight = header.outerHeight() + 15;
	const menuItems = header.find('a');
	const scrollItems = menuItems.map(function () {
		const item = $($(this).attr('href'));
		if (item.length) {
			return item;
		}
	});

	menuItems.on('click', function (e) {
		const href = $(this).attr('href');
		const offsetTop = href === '#' ? 0 : $(href).offset().top - headerHeight + 1;
		$('html, body').stop().animate({
			scrollTop: offsetTop,
		}, 300);
		e.preventDefault();
	  });

	$(window).on('scroll', function () {
		// Get container scroll position.
		const headerOffsetTop = $(this).scrollTop() + headerHeight;

		// Get id of current scroll item.
		let current = scrollItems.map(function () {
			if ($(this).offset().top < headerOffsetTop) {
				return this;
			}
		});

		// Get the id of the current element.
		current = current[current.length - 1];
		const id = current && current.length ? current[0].id : '';

		if (lastID !== id) {
			lastID = id;

			menuItems.not(`[href='#${id}']`).removeClass('current-link');
			menuItems.filter(`[href='#${id}']`).addClass('current-link');
		}
	});
}

function closeMenuOnClick() {
	$('.anchor-link').on('click', () => {
		if (els.html.classList.contains('html--menu-open')) {
			els.html.classList.remove('html--menu-open');
		}
	});
}

if ($('body').hasClass('tmpl-neuronight')) {
	window.addEventListener('load', () => {
		initSliders();
		initGFormLightbox();
		initNavScroll();
		closeMenuOnClick();
		AOS.init();
	});

	window.addEventListener('load', initFloatingButton, 3000);
	window.addEventListener('resize', () => {
		if (!$('.card-container').hasClass('slick-initialized')) {
			initSliders();
		}

		if (!$('.slide-contain').hasClass('slick-initialized')) {
			initSliders();
		}
	});
}
