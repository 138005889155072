/**
 * Main menu functionality for responsive behaviors and dropdowns.
 *
 * @package  Embark
 * @since    1.0.0
 */

/**
 * Import dependencies.
 */
import $ from 'jquery';
import hoverintent from 'hoverintent';
import * as helpers from '../global/helpers';
import { els, breakpoints } from './variables';

/**
 * Menu items that have submenus.
 *
 * @type {NodeList}
 */
const menuItemsWithChildren = els.mainMenu ? els.mainMenu.querySelectorAll('.menu-item-has-children') : null;


/**
 * Screensize that mobile menu is enabled/disabled
 *
 * @type {Int}
 */
const menuBreakPoint = breakpoints.md.min;

/**
 * Class used for menu item triggers.
 *
 * @type {String}
 */
const menuItemTriggerClass = 'menu-item__trigger';

/**
 * Class added to <html> when menu is open.
 *
 * @type {String}
 */
const menuOpenHtmlClass = 'html--menu-open';

/**
 * Class added to navicon when menu is open.
 *
 * @type {String}
 */
const menuOpenNaviconClass = 'navicon__trigger--open';

/**
 * Toggle mobile submenus on click.
 *
 * @param  {Event} e
 */
function handleMenuItemTriggerClick(e) {
	/**
	 * Prevents further propagation of the current event in the capturing and bubbling phases.
	 */
	e.stopPropagation();

	/**
	 * Check if the event target contains the menu item trigger class.
	 */
	if (e.target.classList.contains(menuItemTriggerClass)) {
		/**
		 * Prevent the click from linking to a new page.
		 */
		e.preventDefault();

		$(e.target.previousElementSibling).attr('aria-expanded', (index, attr) => (attr === 'true' ? 'false' : 'true'));
		$(e.target).toggleClass('trigger-open');
		$(e.target.nextElementSibling).toggleClass('open');

		if ($(e.target.previousElementSibling).attr('aria-expanded') === 'false' || typeof $(e.target.previousElementSibling).attr('aria-expanded') === 'undefined') {
			$(e.target.nextElementSibling).removeClass('open');
			if (window.innerWidth < menuBreakPoint) {
				$(e.target.nextElementSibling).slideUp();
			}
		} else {
			$(e.target.nextElementSibling).addClass('open');
			if (window.innerWidth < menuBreakPoint) {
				$(e.target.nextElementSibling).slideDown();
			}
		}
	}
}

/**
 * Maybe close opened submenus.
 */
function maybeCloseSubmenus() {
	if (els.mainMenu) {
		const openSubmenus = els.mainMenu.querySelectorAll('.sub-menu.open');
		if (openSubmenus && window.innerWidth >= menuBreakPoint) {
			helpers.forEach(openSubmenus, (i, submenu) => {
				submenu.style.display = '';
				submenu.classList.remove('open');
			});
		}
	}
}

/**
 * Check if current submenu is fully
 * within the viewport. If not, add a class so that it can be styled accordingly.
 *
 * @param  {Event} e
 */
function checkSubmenuPosition() {
	const submenu = this.querySelector('.sub-menu');
	if (submenu && !helpers.isElementInViewport(submenu) && window.innerWidth >= menuBreakPoint) {
		this.classList.add('submenu-offscreen');
	}
}

/**
 * Add class to the first .current-menu-ancestor to avoid multiple being highlighted.
 *
 * @param  {Event} e
 */
function handleMultipleMenuAncestors() {
	const ancestorItems = document.querySelectorAll('.main-menu > .current-menu-ancestor');
	if (ancestorItems.length > 0) {
		ancestorItems[0].classList.add('current-menu-ancestor--primary');
	}
}

/**
 * Open the mobile menu.
 */
function openMobileMenu() {
	if (!els.naviconTrigger) {
		return;
	}
	els.html.classList.add(menuOpenHtmlClass);
	els.naviconTrigger.classList.add(menuOpenNaviconClass);
}

/**
 * Close the mobile menu.
 */
function closeMobileMenu() {
	if (!els.naviconTrigger) {
		return;
	}
	els.html.classList.remove(menuOpenHtmlClass);
	els.naviconTrigger.classList.remove(menuOpenNaviconClass);
}

/**
 * Toggle the mobile menu.
 */
function toggleMobileMenu() {
	if (els.html.classList.contains(menuOpenHtmlClass)) {
		closeMobileMenu();
	} else {
		openMobileMenu();
		$('.site-header').removeClass('search-open');
	}
}

/**
 * Close the mobile menu when you click off of it.
 *
 * @param  {Event} e
 */
function handleMenuState(e) {
	if (!els.navicon) {
		return;
	}

	/**
	 * If the menu is open, and the event target
	 * is the nav or a child of the nav, exit quickly.
	 */
	if (els.html.classList.contains(menuOpenHtmlClass) &&
	(e.target === els.navContainer || $(e.target).closest(els.navContainer).length > 0)) {
		return;
	}

	/**
	 * If event target is the navicon or any of its children, trigger the toggle method.
	 */
	if (e.target === els.navicon || $(e.target).closest('.navicon__trigger').length > 0) {
		toggleMobileMenu();
	} else {
		/**
		 * Else, this is not the navicon trigger. Close the menu. User has clicked away.
		 */
		closeMobileMenu();
	}
}

/**
 * Update mobile menu top offset and close opened submenus depending on screensize.
 *
 * @param  {Event} e
 */
function handleMenuOnResize() {
	maybeCloseSubmenus();

	if (window.innerWidth >= menuBreakPoint) {
		closeMobileMenu();
	}
}

/**
 * Initialze menu.
 *
 * @param  {Event} e [description]
 */
function initMainMenu(e) {
	handleMultipleMenuAncestors(e);

	/**
	 * Add event listeners for menu items with submenus.
	 */
	if (menuItemsWithChildren) {
		helpers.forEach(menuItemsWithChildren, (i, item) => {
			item.addEventListener('mouseover', () => {
				if (window.innerWidth >= menuBreakPoint) {
					item.classList.add('hover');
				} else {
					item.addEventListener('mouseenter', checkSubmenuPosition);
					item.addEventListener('click', handleMenuItemTriggerClick);
				}
			});

			item.addEventListener('mouseout', () => {
				if (window.innerWidth >= menuBreakPoint) {
					item.classList.remove('hover');
				}
			});
		});
	}

	// Add "open" class on page load so mobile-menu is expanded to currently viewed sub-menu items.
	if (window.innerWidth < menuBreakPoint) {
		$(els.mainMenu).find('[class*="current-"]')
			.addClass('open')
			.find('.sub-menu')
			.show();
	}
}

window.addEventListener('DOMContentLoaded', initMainMenu);
window.addEventListener('resize', helpers.debounce(handleMenuOnResize, 100, false));
document.addEventListener('click', handleMenuState);
