/**
 * CountUp.js
 *
 * @package Embark
 * @since   1.0.1
 * @link https://www.npmjs.com/package/countup.js
 */

/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';
import 'slick-carousel';

function initCarousel(el) {
	const sliderParent = el.closest('.layer');
	let responsiveArgs = [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
			},
		},
		{
			breakpoint: 649,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	];
	if (el.hasClass('quote-carousel')) {
		responsiveArgs = [
			{
				breakpoint: 1231,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
				},
			},
			{
				breakpoint: 851,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 761,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		];
	}
	const slider = el.slick({
		slides: '.item',
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 1,
		dots: true,
		appendDots: sliderParent.find('.em-dots'),
		arrows: false,
		centerMode: false,
		responsive: responsiveArgs,
	});

	sliderParent.find('.em-prev-btn').on('click', () => {
		slider.slick('slickPrev');
	});

	// Next button click handler
	sliderParent.find('.em-next-btn').on('click', () => {
		slider.slick('slickNext');
	});

	// Dot click handler
	sliderParent.children('.em-dots li').on('click', () => {
		const index = $(this).index();
		slider.slick('slickGoTo', index);
	});

	// Update dot active state on slide change
	if (el.hasClass('carousel')) {
		slider.on('afterChange', (event, slick, currentSlide) => {
			let slideIndex = currentSlide;
			console.log('slide index');
			if (el.hasClass('has-2-slides') && currentSlide >= 2) {
				if (currentSlide === 3) {
					slideIndex = 1;
				} else {
					slideIndex = 0;
				}
			}
			const dots = sliderParent.find('.em-dots');
			dots.find('li').removeClass('slick-active');
			dots.find('li').eq(slideIndex).addClass('slick-active');
		});
	}
}
window.addEventListener('load', () => {
	$('.carousel').each((index) => {
		initCarousel($('.carousel').eq(index));
	});
});
window.addEventListener('resize', () => {
	if (!$('.carousel').hasClass('slick-initialized')) {
		$('.carousel').each((index) => {
			initCarousel($('.carousel').eq(index));
		});
	}
});
