/**
 * Sticky header functionality
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Common variables used throughout the project.
 *
 * @since 1.0.0
 */
import $ from 'jquery';
import { els } from './variables';
import * as helpers from '../global/helpers';


/**
 * Tracks the current scroll position on the page.
 *
 * @type {Number}
 */
let currentScrollPoint;
let oldScroll;

/**
 * The point (in pixels) at which the header class should be toggled.
 *
 * @type {Number}
 */
// const headerScrollPoint = 100;

/**
 * Toggle the header scrolled class.
 *
 * @param  {Event} e
 */
function maybeToggleScrolledClass() {
	if (!els.header) {
		return;
	}
	/**
	 * Update the current scroll position.
	 *
	 * @type {Number}
	 */
	currentScrollPoint = (els.html && els.html.scrollTop) || els.body.scrollTop;
	const header = $('#js-site-header');
	const scrollPoint = header.outerHeight();
	/**
	 * Check scroll status and toggle .scrolled accordingly.
	 */
	if (currentScrollPoint > scrollPoint) {
		els.header.classList.add('scrolled');
		els.header.classList.remove('fadeIn');
	} else if (els.header.classList.contains('scrolled')) {
		els.header.classList.remove('scrolled');
		els.header.classList.add('fadeIn');
	}

	if (window.outerWidth <= 768) {
		if (oldScroll < window.scrollY && els.header.classList.contains('scrolled')) {
			els.header.classList.add('scroll-down');
		} else {
			els.header.classList.remove('scroll-down');
		}

		oldScroll = window.scrollY;
	}

	if ($('.side-nav').length > 0 && !$('body').hasClass('has-side-nav')) {
		$('body').addClass('has-side-nav');
	}
}

window.addEventListener('scroll', () => {
	helpers.debounce(maybeToggleScrolledClass());
});
window.addEventListener('load', maybeToggleScrolledClass);
