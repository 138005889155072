/**
 * CountUp.js
 *
 * @package Embark
 * @since   1.0.1
 * @link https://www.npmjs.com/package/countup.js
 */

/**
 * jQuery
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';
import * as helpers from '../global/helpers';

function emInitCountup() {
	const	stats = $('.statistics');

	stats.each(function () {
		if ($(this).length > 0 && helpers.elementInViewport($(this)[0]) && !$(this).hasClass('active')) {
			const numbers = $(this).find('.num');
			numbers.each(function () {
				let decimal = 0;
				let counter = 0;
				if ($(this).attr('data-number').indexOf('.') > 0) {
					decimal = 1;
				}

				if ($(this).attr('data-number').indexOf(',') > 0) {
					counter = parseInt($(this).data('number').replace(/,/g, ''));
				} else {
					counter = parseInt($(this).data('number'));
				}

				$(this).prop('Counter', 0).animate({
					Counter: counter,
				}, {
					duration: 4000,
					easing: 'swing',
					step(now) {
						if (decimal) {
							$(this).text(Math.round(now * 10) / 10);
						} else {
							$(this).text(Math.round(now * 1));
						}
					},
					complete() {
						$(this).text($(this).attr('data-number'));
					},
				});
			});

			$(this).addClass('active');
		}
	});
}

window.addEventListener('load', emInitCountup);
window.addEventListener('scroll', () => {
	helpers.debounce(emInitCountup());
});
