/**
 * Defines helper functions
 *
 * A collection of helpful functions that may be imported into any js partial.
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

/*eslint-disable */

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @since 1.0.0
 *
 * @param  {Function} 	func      	Function to debounce
 * @param  {Number} 	wait      	Time to wait in milliseconds
 * @param  {Boolean} 	immediate 	If true is passed, trigger the function on the leading edge, instead of the trailing.
 * @return {Function}
 */
export function debounce(func, wait = 100, immediate = true) {
	let timeout;
	return function () {
		let context = this,
			args = arguments;
		const later = function () {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) {
			func.apply(context, args);
		}
	};
}

/**
 * Loop through an array/nodelist and call the passed callback function.
 *
 * @since 1.0.0
 *
 * @param  {Array}   	array    	Array to be looped over.
 * @param  {Function} 	callback 	Callback function.
 * @param  {???}   		scope
 */
export function forEach(array, callback, scope) {
	// Stores the length of the array.
	const numItems = array.length;

	// Loops through the array.
	for (let i = 0; i < numItems; i++) {
		callback.call(scope, i, array[i]);
	}
}

/**
 * Append a hash to the current URL.
 *
 * @since 1.0.0
 *
 * @param {String} hash
 */
export function addHashToURL(hash) {
	if (history.pushState) {
		const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${hash}`;
		window.history.pushState({
			path: newurl,
		}, '', newurl);
	}
}

/**
 * Scroll the document to the provided element.
 *
 * @since 1.0.0
 *
 * @param  {Object} 	el     	jQuery object
 * @param  {Integer} 	offset 	Offset from the top of the viewport
 */
export function scrollToEl(el, offset) {
	$('html, body').animate({
		scrollTop: el.offset().top - offset,
	}, 500);
}

export function elementInViewport(el) {
	const viewport = {};
	viewport.top = window.pageYOffset;
	viewport.bottom = viewport.top + window.innerHeight;

	const bounds = {};
	bounds.top = el.getBoundingClientRect().top + window.pageYOffset;
	bounds.bottom = bounds.top + el.clientHeight;

	return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
	(bounds.top <= viewport.bottom && bounds.top >= viewport.top);
}

/**
 * Check the userAgent for common touch devices.
 *
 * @since 1.0.1
 */
export function isTouchDevice() {
	return navigator.userAgent.match(/Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i) !== null;
}

/* eslint-enable */
